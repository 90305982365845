import { ref } from '@vue/composition-api'
import store from '@/store'

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    priceRangeDefined: 'all',
    priceRange: [0, 1000],
    categories: 'All',
    industries: 'All',
    ratings: [],
    page: 1,
    perPage: 9,
  })

  const filterOptions = {
    priceRangeDefined: [
      { text: 'All', value: 'all' },
      { text: '<= $10', value: '<=10' },
      { text: '$10 - $100', value: '10-100' },
      { text: '$100 - $500', value: '100-500' },
      { text: '>= $500', value: '>=500' },
    ],
    categories: ['All','Expert','Seeker'],
    industries: [
      'All',
      'Accounting',
      'Airlines/Aviation',
      'Alternative Dispute Resolution',
      'Alternative Medicine',
      'Animation',
      'Apparel & Fashion',
      'Architecture & Planning',
      'Arts & Crafts',
      'Automotive',
      'Aviation & Aerospace',
      'Banking',
      'Biotechnology',
      'Broadcast Media',
      'Building Materials',
      'Business Supplies & Equipment',
      'Capital Markets',
      'Chemicals',
      'Civil Engineering',
      'Commercial Real Estate',
      'Computer & Network Security',
      'Computer Games',
      'Computer Hardware',
      'Computer Networking',
      'Computer Software',
      'Construction',
      'Consumer Electronics',
      'Consumer Goods',
      'Consumer Services',
      'Cosmetics',
      'Dairy',
      'Defense & Space',
      'Design',
      'E-Learning',
      'Education Management',
      'Electrical & Electronic Manufacturing',
      'Entertainment',
      'Environmental Services',
      'Events Services',
      'Executive Office',
      'Facilities Services',
      'Farming',
      'Financial Services',
      'Fine Art',
      'Fishery',
      'Food & Beverages',
      'Food Production',
      'Fundraising',
      'Furniture',
      'Gambling & Casinos',
      'Glass',
      'Government Administration',
      'Government Relations',
      'Graphic Design',
      'Health',
      'Higher Education',
      'Hospital & Health Care',
      'Hospitality',
      'Human Resources',
      'Import & Export',
      'Individual & Family Services',
      'Industrial Automation',
      'Information Services',
      'Information Technology & Services',
      'Insurance',
      'International Affairs',
      'International Trade and Development',
      'Internet',
      'Investment Banking',
      'Judiciary',
      'Law Enforcement',
      'Law Practice',
      'Legal Services',
      'Legislative Office',
      'Leisure',
      'Libraries',
      'Logistics & Supply Chain',
      'Luxury Goods & Jewelry',
      'Machinery',
      'Management Consulting',
      'Maritime',
      'Market Research',
      'Marketing & Advertising',
      'Mechanical or Industrial Engineering',
      'Media Production',
      'Medical Device',
      'Medical Practice',
      'Mental Health Care',
      'Military',
      'Mining & Metals',
      'Mobile Games',
      'Motion Pictures & FIlm',
      'Museums & Institutions',
      'Music',
      'Nanotechnology',
      'Newspapers',
      'Non-profit Organization Management',
      'Oil & Energy',
      'Online Media',
      'Outsourcing/Offshoring',
      'Package/Freight Delivery',
      'Packaging & Containers',
      'Paper & Forest Products',
      'Performing Arts',
      'Pharmaceuticals',
      'Philanthropy',
      'Photography',
      'Plastics',
      'Political Organization',
      'Primary/Secondary Education',
      'Printing',
      'Professional Training & Coaching',
      'Program Development',
      'Public Policy',
      'Public Relations & Communications',
      'Public Safety',
      'Publishing',
      'Railroad Manufacture',
      'Ranching',
      'Real Estate',
      'Recreational Facilities & Services',
      'Religious Institution',
      'Renewables & Environment',
      'Research',
      'Restaurants',
      'Retail',
      'Security & Investigations',
      'Semiconductors',
      'Shipbuilding',
      'Sporting Goods',
      'Sports',
      'Staffing & Recruiting',
      'Supermarkets',
      'Telecommunications',
      'Textiles',
      'Think Tanks',
      'Tobacco',
      'Translation & Localization',
      'Transportation/Trucking/Railroad',
      'Utilities',
      'Venture Capital & Private Equity',
      'Veterinary',
      'Warehousing',
      'Wine & Spirits',
      'Wireless',
      'Writing & Editing',
    ],
    ratings: [
      { rating: 4 },
      { rating: 3 },
      { rating: 2 },
      { rating: 1 },
    ],
  }

  // Sorting
  const sortBy = ref({ text: 'Featured', value: 'featured' })
  const sortByOptions = [
    { text: 'Featured', value: 'featured' },
    { text: 'Lowest', value: 'price-asc' },
    { text: 'Highest', value: 'price-desc' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useShopUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalProfiles = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProfiles,
  }
}

export const useShopRemoteData = () => {
  const products = ref([])
  const fetchProducts = (...args) => store.dispatch('app-ecommerce/fetchProducts', ...args)

  return {
    products,
    fetchProducts,
  }
}
