<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results" v-if="loading == false">
                {{ totalProfiles }} Profiles Found
              </div>
              <div class="d-flex justify-content-center" v-if="loading">
                <b-spinner label="Loading..." variant="primary" class="mr-1"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </div>
            <div class="view-options d-flex">

              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Search Profiles"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Profiles -->
    <section :class="itemView">
      <b-card
        v-for="profile in profiles"
        :key="profile.id"
        class="ecommerce-card"
        no-body
      >
        <div class="text-center">
          <b-link :to="{ name: 'apps-users-view', params: profile }">
            <!-- <b-img
              :text="avatarText(profile.fullName)"
              :alt="`${profile.fullName}-${profile.id}`"
              fluid
              class="card-img-top"
              :src="profile.image"
            />  -->
            <b-avatar
              :src="profile.image"
              :text="avatarText(profile.fullName)"
              :variant="`light-primary`"
              class="card-img-top"
              size="365px"
              rounded
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="badge badge-light-primary position-absolute" style="top:0; left:0" v-if="profile.linkedinID"><b-img style="height:30px;" :src="require('@/assets/images/misc/linkedinvalidated.png')"></b-img></div>
          <div class="badge badge-light-primary position-absolute" style="top:0; right:0" v-if="profile.type !== 'Both'">{{ profile.type }} </div>
          <div class="badge badge-light-primary position-absolute" style="top:0; right:0" v-else>Expert and Seeker</div>
          <div class="item-wrapper">
            <div class="item-rating" v-if="profile.type !== 'Seeker'">
              <h6 class="float-left" style="margin-top:3px;">
                {{ profile.reviewCount }}
              </h6>
              <ul class="unstyled-list list-inline" style="margin-left:30px;">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= profile.rating}, star <= profile.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div>
            <div>
              <h6 class="item-price" v-if="profile.type !== 'Seeker'">
                ${{ profile.rate }}
              </h6>
            </div>
          </div>

          <h6 class="item-name">
            <span class="badge badge-light-primary float-right"> {{ profile.industry }} </span>
            <b-link
              class="text-body"
              :to="{ name: 'apps-users-view', params: profile }"
            >
              {{ profile.fullName }}
            </b-link>

            <b-card-text class="item-company">
            </b-card-text>
          </h6>
          <b-card-text class="item-description mt-1">
            {{ profile.tagline }}
          </b-card-text>

        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price" v-if="profile.type !== 'Seeker'">
                ${{ profile.rate }}
              </h4>
            </div>
          </div>
          <!-- <b-button
            variant="light"
            tag="a"
            class="btn-wishlist"
            @click="toggleProductInWishlist(profile)"
          >
            <feather-icon
              icon="StarIcon"
              class="mr-50"
              :class="{ 'text-warning fill-current': profile.isInWishlist }"
            />
            <span>Favorited</span>
          </b-button> -->
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            :to="{ name: 'apps-users-view', params: profile }"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="mr-50"
            />
            <span>More Details</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <!-- <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProfiles"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section> -->

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
    <b-modal
      id="modal-profile"
      centered
      :no-close-on-backdrop="true"
    >
      <b-card
        no-body
        class="card-developer-meetup"
      >
        <div class="bg-light-primary rounded-top text-center">
          <b-img
            :src="require('@/assets/images/illustration/api.svg')"
            alt="Review Image"
            height="170"
          />
        </div>
        <b-card-body>
          <!-- metting header -->
          <div class="d-flex align-items-center">
            <div class="my-auto">
                Need to complete your profile before you can search for experts and seekers.
            </div>
          </div>
        </b-card-body>
      </b-card>
      <template #modal-header>
        <div class="w-100">
          <h5 class="modal-title" id="waitlistModalSucessTitle">Complete Profile</h5>
        </div>
      </template>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            ref="acceptButton"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            size="md"
            class="float-right"
            @click="routeProfile"
          >
            Go to Profile
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-profile2"
      ok-only
      ok-title="Accept"
      centered
      title="Complete Profile"
      data-backdrop="static"
      data-keyboard="false"
    >
      <b-card-text>Need to complete your profile before you can search for experts and seekers.</b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BFormRadioGroup, BSpinner, BFormRadio, BRow, BCol, BModal, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { watch, ref } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useECommerceShop'
import { useEcommerceUi } from '../useEcommerce'
import { getFirestore, getDocs, query, orderBy, where, limit, collection} from 'firebase/firestore'
import { getAuth } from "firebase/auth"

const db = getFirestore()
const auth = getAuth()
//import { getFunctions, httpsCallable } from "firebase/functions";
// const functions = getFunctions()

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BSpinner,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BAvatar,
    BModal,

    // SFC
    ShopLeftFilterSidebar,
  },
  mounted() {
    // Dont search until the've setup a profle
    const userData = JSON.parse(localStorage.getItem('userData'))
    if(!userData.profileComplete){
      this.$bvModal.show('modal-profile')
    }
  },
  methods: {
    routeProfile(){
      this.$router.push('/app/create-profile')
    }
  },
  setup() {
    const profiles = ref([])
    const unFilteredProfiles = ref([])
    const totalProfiles = ref(0)
    const loading = ref(true)

    const userData = JSON.parse(localStorage.getItem('userData'))

    const { filters, filterOptions, sortBy, sortByOptions } = useShopFiltersSortingAndPagination()
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()
    const { itemView, itemViewOptions, totalProfiiles } = useShopUi()
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const fetchProfiles = async() => {
      loading.value=true
      // TODO Paginate and send filters
      // const getUserProfiles = httpsCallable(functions, 'getUserProfiles')
      // const res = await getUserProfiles({})
      // if(res && res.data) unFilteredProfiles.value = res.data.profiles

      let unsortedProfileList = []
      const snapshot = await getDocs(query(collection(db, "profiles"), where("profileComplete", "==", true), orderBy("createdAt", "desc"), limit(200)))
      snapshot.forEach(doc => {
        let profile = doc.data()
        profile.id = doc.id
        profile.date = new Date(profile.createdAt.seconds * 1000);
        unsortedProfileList.push(profile)
      })
      unFilteredProfiles.value = unsortedProfileList
      filterProfiles()
      loading.value=false
    }

    // Dont search until the've setup a profle
    if(userData.profileComplete) fetchProfiles()

    const filterProfiles = () => {
      const queryLowered = filters.value.q.toLowerCase()

      // Filter profiles
      const filteredData = unFilteredProfiles.value.filter(profile => (
        profile.fullName.toLowerCase().includes(queryLowered) ||
        profile.industry.toLowerCase().includes(queryLowered) ||
        profile.tagline.toLowerCase().includes(queryLowered) ||
        profile.bio.toLowerCase().includes(queryLowered) ||
        profile.username.toLowerCase().includes(queryLowered) ||
        ((profile.skills)?profile.skills.filter((a) => {  return a.toLowerCase().includes(queryLowered) }).length > 0:false) ||
        ((profile.pSkills)?profile.pSkills.filter((a) => {  return a.toLowerCase().includes(queryLowered) }).length > 0:false)) &&
        ((filters.value.categories === 'All')? true : profile.type === filters.value.categories) &&
        ((filters.value.industries.includes('All'))? true : filters.value.industries.includes(profile.industry)) &&
        ((profile.rate >= filters.value.priceRange[0] && profile.rate <= filters.value.priceRange[1]) || !profile.rate) &&
        ((filters.value.ratings.length > 0)? filters.value.ratings.includes(Math.floor(profile.rating)) : true )
      )

      let sortDesc = false
      const sort = sortBy.value.value
      const sortByKey = (() => {
        if (sort === 'price-desc') {
          sortDesc = true
          return 'rate'
        }
        if (sort === 'price-asc') {
          return 'rate'
        }
        sortDesc = true
        return 'id'
      })()

      const sortedData = filteredData.sort(sortCompare(sortByKey))
      if (sortDesc) sortedData.reverse()

      //const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))
      //paginatedData.forEach(product => {
        /* eslint-disable no-param-reassign */
        //product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
        /* eslint-enable */
      //})
      totalProfiles.value = sortedData.length;
      profiles.value = sortedData
    }

    const sortCompare = key => (a, b) => {
      const fieldA = a[key]
      const fieldB = b[key]

      if(key == 'rate'){
        return fieldA - fieldB;
      }else{
        let comparison = 0
        if (fieldA >= fieldB) {
          comparison = 1
        } else if (fieldA < fieldB) {
          comparison = -1
        }
        return comparison
      }
    }

    watch([filters, sortBy], () => { filterProfiles() }, { deep: true })

    return {
      avatarText,

      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProfiiles,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      profiles,
      totalProfiles,
      loading,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
